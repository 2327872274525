import { ParkInfo, StateInfo } from "../types";
import { states, abbreviations } from './states';
import { BoundingBox } from "@npmap/geotools";

// Cache to store all park data to avoid redundant API calls
let allParkDataCache: ParkInfo[] = [];

/**
 * Fetches all park data from the API.
 * 
 * @returns A promise that resolves to an array of ParkInfo objects.
 */
export const getAllParks = async (): Promise<ParkInfo[]> => {
    if (allParkDataCache.length) {
        // Return cached data if already fetched
        return allParkDataCache;
    }

    const completeParkList: ParkInfo[] = [];
    const cartoUrl = new URL('https://carto.nps.gov/user/parktiles/api/v2/sql');
    cartoUrl.searchParams.append('q', allParkQuery);
    cartoUrl.searchParams.append('format', 'json');
       
    try {
        const response = await fetch(cartoUrl);
        const json = await response.json();
        json.rows.forEach((row: cartoResponse) => {
            const parkData = responseToParkInfo(row);
            completeParkList.push(parkData);
        });
    } catch (e) {
        console.error('Error fetching park data:', e);
    }

    // Cache the fetched park data for future use
    allParkDataCache = completeParkList;
    return allParkDataCache;
};

// SQL query to fetch park data from the API
const allParkQuery = `
WITH bboxes AS (
    SELECT
        unit_code,
        BOX2D(ST_Extent(park_boundary_polygon.the_geom))::text AS bbox 
    FROM
        parktiles.park_boundary_polygon
    GROUP BY
        unit_code
), park_labels AS (
    SELECT DISTINCT ON (unit_code)
        geometry,
        label_name AS name,
        label_long AS fullname,
        state AS states,
        designation,
        url,
        unit_code
    FROM 
        parktiles.cartographic_park_label 
    WHERE 
        main_label 
        AND park_class NOT IN ('ncr subunit', 'subunit') 
        AND unit_code IS NOT NULL
    ORDER BY 
        unit_code -- Ensuring a consistent ordering for the DISTINCT ON
)
SELECT
    ST_X(COALESCE(park_labels.geometry, sd_parks.the_geom)) AS "lng",
    ST_Y(COALESCE(park_labels.geometry, sd_parks.the_geom)) AS "lat",
    COALESCE(sd_parks.fullname, park_labels.fullname) AS "parkFullName",
    COALESCE(sd_parks.name, park_labels.name) AS "parkName",
    COALESCE(sd_parks.parkcode, park_labels.unit_code) AS "unitCode",
    COALESCE(sd_parks.designation, park_labels.designation) as "designation",
    COALESCE(sd_parks.url, park_labels.url) AS "url",
    COALESCE(sd_parks.states, park_labels.states) AS "states",
    sd_parks.image_1_title AS "imageAltText",
    sd_parks.image_1_url AS "imageUrl",
    COALESCE(park_labels.name, sd_parks.name) AS "parkLabel",
    COALESCE(bboxes.bbox, BOX2D(COALESCE(park_labels.geometry, sd_parks.the_geom))::text) AS "bbox"
FROM
    parktiles.sd_parks
FULL OUTER JOIN
    park_labels ON sd_parks.parkcode = park_labels.unit_code
LEFT JOIN
    bboxes ON COALESCE(sd_parks.parkcode,park_labels.unit_code)  = bboxes.unit_code
`;

// Define the structure of the API response
type cartoResponse = {
    lng: number;
    lat: number;
    parkFullName: string;
    parkName: string;
    parkLabel: string;
    unitCode: string;
    designation: string;
    url: string;
    states: string;
    imageAltText: string;
    imageUrl: string;
    bbox?: string;
};

/**
 * Converts API response data to ParkInfo format.
 * 
 * @param response - The response object from the API.
 * @param existingData - An optional object to merge with the new data.
 * @returns A ParkInfo object containing the park's data.
 */
const responseToParkInfo = (response: cartoResponse, existingData: Partial<ParkInfo> = {}): ParkInfo => {
    const bbox = parseBoundingBox(response);

    return {
        ...response,
        states: (response.states || '').split(',').map(state => state.trim()),
        bbox,
        ...existingData
    };
};

/**
 * Parses the bounding box string from the API response into a BoundingBox object.
 * 
 * @param response - The response object containing the bounding box string.
 * @returns A BoundingBox object representing the park's bounding box.
 */
const parseBoundingBox = (response: cartoResponse): BoundingBox => {
    if (!response.bbox) {
        return new BoundingBox([{ x: response.lng, y: response.lat }], '4326');
    }

    return new BoundingBox(
        response.bbox
            .replace('BOX(', '')
            .replace(')', '')
            .split(',')
            .map(coord => {
                const [x, y] = coord.split(' ').map(parseFloat);
                return { x, y };
            }),
        '4326'
    );
};

/**
 * Gets a list of states from their abbreviations.
 * 
 * @param abbreviationList - An array of state abbreviations.
 * @returns An array of StateInfo objects representing the states.
 */
export const getStateList = (abbreviationList: string[]): StateInfo[] => {
    return Array.from(new Set(abbreviationList)).map((abbreviation) => {
        const state = abbreviations[abbreviation.toUpperCase()];
        const bbox = states[state];
        return {
            state,
            abbreviation: state,
            bbox
        };
    });
};

/**
 * Gets a list of parks, merging the provided data with cached data.
 * 
 * @param parkInfo - An array of partial ParkInfo objects.
 * @returns An array of ParkInfo objects with complete data.
 */
export const getParkList = (parkInfo: Partial<ParkInfo>[]): ParkInfo[] => {
    const allParks = allParkDataCache;

    // Create a lookup map for park data by unitCode
    const parkDataLookup = new Map<string, Partial<ParkInfo>>(
        allParks.map(park => [park.unitCode as string, park])
    );

    // Merge the provided park info with the cached data
    const selectedParks = parkInfo.reduce<ParkInfo[]>((acc, park) => {
        const unitCode = typeof park.unitCode === 'string' && park.unitCode.toLowerCase();
        if (unitCode && parkDataLookup.has(unitCode)) {
            // Merge the cached park data with the incoming park data
            acc.push({
                ...parkDataLookup.get(unitCode),
                ...park
            } as ParkInfo);
        } else if (park.lng && park.lat) {
            // If the park isn't in the cache but has valid coordinates, add it directly
            acc.push(park as ParkInfo);
        }

        return acc;
    }, []);

    return selectedParks;
};