import { Map as NPMap } from "@npmap/base";
import { ParkInfo } from "../types";
import type { MapLayerMouseEvent } from "maplibre-gl";

const hoverLayerOn = (e: MapLayerMouseEvent) => hoverPark(e.target as NPMap, e.features && e.features[0].properties?.unitCode, true);
const hoverLayerOff = (e: MapLayerMouseEvent) => hoverPark(e.target as NPMap, e.features && e.features[0].properties?.unitCode, false)

export function hoverLayer(map: NPMap, layerId: string, remove: boolean = false) {
    if (!remove) {
        map.on('mousemove', layerId, hoverLayerOn);
        map.on('mouseleave', layerId, hoverLayerOff);
    } else {
        map.off('mousemove', layerId, hoverLayerOn);
        map.off('mouseleave', layerId, hoverLayerOff);
    }
}

const hoverPark = (map: NPMap, unitCode: string | undefined, hover: boolean) => {
    const layerId = 'selectedParkPins';
    const layer = map.getLayer(layerId);

    if (unitCode) {
        const parkCard = map.getContainer().querySelector(`.park-card-${unitCode}`);
        if (parkCard) (parkCard as HTMLDivElement).style.backgroundColor = hover ? '#effbe2' : 'white';
    } else {
        const parkCards = map.getContainer().querySelectorAll('.park-card');
        parkCards.forEach((card) => (card as HTMLDivElement).style.backgroundColor = 'white');
    }

    if (layer) {
        const { source, sourceLayer } = layer;
        const renderedFeatures = map.queryRenderedFeatures({ layers: [layerId] });

        renderedFeatures.forEach((feature) => {
            map.setFeatureState(
                { source, sourceLayer, id: feature.id },
                { hover: hover && feature.properties?.unitCode === unitCode }
            );
        });
    }
}

export default function updateCards(parkInfo: ParkInfo[], container: HTMLDivElement, map: NPMap) {
    const sortedParkNames = parkInfo.map(park => park.parkName).sort((a, b) => a.localeCompare(b));
    const parkLookUpMap = new Map<string, ParkInfo>(
        parkInfo.map(park => [park.parkName, park])
    );

    const cards = sortedParkNames
        .map(name => parkLookUpMap.get(name))
        .filter((info): info is ParkInfo => info !== undefined)
        .map((park, index) => {
            const designationDiv = document.createElement('div');
            designationDiv.textContent = park.designation;
            designationDiv.style.textTransform = 'uppercase';
            designationDiv.style.fontWeight = '600';
            designationDiv.style.color = '#555';
            const nameDiv = document.createElement('div');
            nameDiv.textContent = park.parkName;
            nameDiv.style.fontWeight = 'bold';
            nameDiv.style.fontSize = '1.3em';
            nameDiv.style.color = '#5A6A39';
            const statesDiv = document.createElement('div');
            statesDiv.textContent = (park.states || []).join(', ');
            statesDiv.style.color = '#555';
            const cardDiv = document.createElement('div');
            cardDiv.appendChild(designationDiv);
            cardDiv.appendChild(nameDiv);
            cardDiv.appendChild(statesDiv);
            cardDiv.tabIndex = 1000 + index;

            const selectCard = () => {
                let popupListener = map._cache.get('PopupListener');
                if (popupListener) {
                    popupListener.displayPopup({
                        layer: { id: 'selectedParkPins' },
                        properties: { ...park }
                    },
                        [park.lng, park.lat]
                    );
                }

                // Check if the park's location is within the current map view
                const bounds = map.getBounds();
                if (!bounds.contains([park.lng, park.lat])) {
                    // If the park is not in the current view, center the map on it
                    map.flyTo({ center: [park.lng, park.lat], zoom: map.getZoom() });
                }

            }

            cardDiv.addEventListener('click', () => selectCard());
            cardDiv.addEventListener('keydown', (event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                    selectCard();
                }
            });
            cardDiv.addEventListener('focus', () => {
                hoverPark(map, park.unitCode, true);
            });
            cardDiv.addEventListener('blur', () => {
                hoverPark(map, park.unitCode, false);
            });

            cardDiv.addEventListener('mouseover', () => {
                hoverPark(map, park.unitCode, true);
            });

            cardDiv.addEventListener('mouseout', () => {
                hoverPark(map, park.unitCode, false);
            });

            cardDiv.style.backgroundColor = 'white';
            cardDiv.style.margin = '10px';
            cardDiv.style.padding = '10px';
            cardDiv.style.marginLeft = '20px';
            cardDiv.style.marginRight = '20px';
            cardDiv.style.borderRadius = '10px';

            cardDiv.style.cursor = 'pointer';
            cardDiv.style.boxShadow = '0 0 5px 0 rgba(0,0,0,0.5)';
            cardDiv.classList.add('park-card');
            cardDiv.classList.add('park-card-' + park.unitCode);

            return cardDiv;
        });

    container.innerHTML = '';
    cards.forEach((cardDiv) => container.appendChild(cardDiv));
    if (container.parentElement) container.parentElement.style.zIndex = '1000';
};