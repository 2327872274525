import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from "geojson";
import type { GeoJSONSourceDiff } from "maplibre-gl";

/**
 * Options for specifying field names in the input objects.
 *
 * @template T - The type of the objects in the array.
 */
interface GeoJSONOptions {
    lat?: string;
    lng?: string;
    primaryKey?: string;
}

/**
 * Converts an array of objects into a GeoJSON FeatureCollection.
 *
 * @template T - The type of the objects in the array.
 * @param {T[]} arr - The array of objects to convert.
 * @param {GeoJSONOptions<T>} [options] - Optional settings for latitude, longitude, and primary key fields.
 * @returns {FeatureCollection} - The resulting GeoJSON FeatureCollection.
 */
export const arrayToGeoJSON = <T>(
    arr: T[],
    options: GeoJSONOptions = {}
): FeatureCollection => {
    const { lat = 'lat', lng = 'lng', primaryKey = 'unitCode' } = options;

    const features: Feature[] = arr.reduce<Feature[]>((acc, r, index) => {
        const row = r as Record<string, string>; // Convert the type
        const latValue = Number(row[lat]);
        const lngValue = Number(row[lng]);

        if (isNaN(latValue) || isNaN(lngValue)) {
            console.warn(`Skipping row ${index}: Invalid lat/lng values`, { lat: row[lat], lng: row[lng] });
            return acc;
        }

        const feature: Feature = {
            type: 'Feature',
            id: primaryKey in row ? simpleHash(stringify(row[primaryKey])) : index,
            geometry: {
                type: 'Point',
                coordinates: [lngValue, latValue]
            },
            properties: Object.fromEntries(
                Object.entries(row)
                    .filter(([key]) => key !== lat && key !== lng)
                    .map(([key, value]) => [key, stringify(value)])
            )
        };

        acc.push(feature);
        return acc;
    }, []);

    return {
        type: 'FeatureCollection',
        features
    };
};

/**
 * A simple hash function based on the djb2 algorithm, designed for creating hash values for strings.
 * Maplibre prefers using numbers for its indexes instead of strings, so this function is useful for that purpose.
 * @param str The string to hash
 * @returns A 32-bit hash number
 */
const simpleHash = (str: string): number => {
    let hash = 5381;
    for (let i = 0; i < str.length; i++) {
        hash = (hash * 33) ^ str.charCodeAt(i);
    }
    return hash >>> 0; // Convert to a 32bit positive integer
}

/**
 * Converts any input into a string representation.
 * 
 * @param input - The value to be converted to a string.
 * @returns A string representation of the input.
 */
const stringify = <T>(input: T): string => {
    if (input === null || input === undefined) {
        return String(input);
    }

    if (typeof input === 'string' || typeof input === 'number' || typeof input === 'boolean') {
        return String(input);
    }

    if (input instanceof Date) {
        return input.toISOString();
    }

    if (typeof input === 'object') {
        if (Array.isArray(input)) {
            return `[${input.map(stringify).map(v=> `"${v}"`).join(', ')}]`;
        }

        if (input.toString !== Object.prototype.toString) {
            return input.toString();
        }

        try {
            return JSON.stringify(input);
        } catch (error) {
            console.warn('Failed to stringify object:', error);
            return '[Object]';
        }
    }

    if (typeof input === 'symbol') {
        return input.toString();
    }

    if (typeof input === 'function') {
        return '[Function]';
    }

    return '[Unknown]';
};

/**
 * Creates a diff of features to add and remove between original and new data.
 *
 * @param {FeatureCollection} originalData - The original set of features.
 * @param {FeatureCollection} newData - The new set of features to compare.
 * @returns {GeoJSONSourceDiff} - An object containing arrays of features to add and IDs to remove.
 */
export function createDiff(originalData: FeatureCollection, newData: FeatureCollection): GeoJSONSourceDiff {
    // Extract current data IDs from the original set of features
    const currentDataIds = new Set(originalData.features.map(f => f.id));

    // Filter out features that are not present in the original data
    const add = newData.features.filter(f => f.id !== undefined && !currentDataIds.has(f.id));

    // Create a set of new data IDs for quick lookup
    const newDataIds = new Set(newData.features.map(f => f.id));

    // Determine which data IDs have been removed
    const remove = Array.from(currentDataIds).filter(id => !newDataIds.has(id)) as [string | number];

    return { add, remove };
}

/**
 * Converts an array of features into a FeatureCollection.
 * 
 * @param {Feature[]} features - The array of features to convert.
 * @returns {FeatureCollection} - A FeatureCollection containing the provided features.
 */
export function featuresToCollection(features: Feature[]): FeatureCollection {
    return {
        type: 'FeatureCollection',
        features
    };
}