/**
 * A generic debouncer class to delay function execution until a certain period
 * has elapsed since the last time it was invoked.
 *
 * @template T - The function signature that the debouncer will handle.
 */
export default class Debouncer<T extends (...args: any[]) => void> {
    private timeoutId: ReturnType<typeof setTimeout> | null = null;
    private readonly callback: T;
    private readonly timeout: number;

    /**
     * Creates an instance of Debouncer.
     *
     * @param {T} callback - The function to debounce.
     * @param {number} timeout - The debounce delay in milliseconds.
     */
    constructor(callback: T, timeout: number,) {
        this.timeout = timeout;
        this.callback = callback;
    }

    /**
     * Debounces the callback function, ensuring it is only called once per specified timeout.
     *
     * @param {Parameters<T>} args - The arguments to pass to the callback function.
     */
    public call(...args: Parameters<T>): void {
        // Clear any existing timeout
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }

        // Set a new timeout to call the callback function
        this.timeoutId = setTimeout(() => {
            this.callback(...args);
            this.timeoutId = null; // Clear the timeout ID after execution
        }, this.timeout);
    }

    /**
     * Cancels the debounced function if it hasn't been executed yet.
     */
    public cancel(): void {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
            this.timeoutId = null;
        }
    }
}