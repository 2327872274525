// TODO, this can come from a service or a library

import { BBox } from "geojson";

export const states = {
    "Alabama": [-88.5, 30.1, -84.9, 35],
    "Alaska": [-179.99, 51.2, -129.28, 71.4],
    "American Samoa": [-171.1, -14.6, -168.1, -11],
    "Arizona": [-114.8, 31.3, -109, 37],
    "Arkansas": [-94.6, 33, -89.6, 36.5],
    "California": [-124.5, 32.5, -114.1, 42],
    "Colorado": [-109.1, 37, -102, 41],
    "Commonwealth of the Northern Mariana Islands": [144.8, 14, 146.2, 20.6],
    "Connecticut": [-73.7, 41, -71.8, 42.1],
    "Delaware": [-75.8, 38.5, -75, 39.8],
    "District of Columbia": [-77.1, 38.8, -76.9, 39],
    "Florida": [-87.6, 24.4, -80, 31],
    "Georgia": [-85.6, 30.4, -80.8, 35],
    "Guam": [144.6, 13.2, 145, 13.7],
    "Hawaii": [-178.4, 18.9, -154.8, 28.5],
    "Idaho": [-117.2, 42, -111, 49],
    "Illinois": [-91.5, 37, -87, 42.5],
    "Indiana": [-88.1, 37.8, -84.8, 41.8],
    "Iowa": [-96.6, 40.4, -90.1, 43.5],
    "Kansas": [-102.1, 37, -94.6, 40],
    "Kentucky": [-89.6, 36.5, -82, 39.1],
    "Louisiana": [-94, 28.9, -88.8, 33],
    "Maine": [-71.1, 42.9, -66.9, 47.5],
    "Maryland": [-79.5, 37.9, -75, 39.7],
    "Massachusetts": [-73.5, 41.2, -69.9, 42.9],
    "Michigan": [-90.4, 41.7, -82.1, 48.3],
    "Minnesota": [-97.2, 43.5, -89.5, 49.4],
    "Mississippi": [-91.7, 30.1, -88.1, 35],
    "Missouri": [-95.8, 36, -89.1, 40.6],
    "Montana": [-116, 44.4, -104, 49],
    "Nebraska": [-104.1, 40, -95.3, 43],
    "Nevada": [-120, 35, -114, 42],
    "New Hampshire": [-72.6, 42.7, -70.6, 45.3],
    "New Jersey": [-75.6, 38.8, -73.9, 41.4],
    "New Mexico": [-109.1, 31.3, -103, 37],
    "New York": [-79.8, 40.5, -71.8, 45],
    "North Carolina": [-84.3, 33.8, -75.4, 36.6],
    "North Dakota": [-104, 45.9, -96.6, 49],
    "Ohio": [-84.8, 38.4, -80.5, 42.3],
    "Oklahoma": [-103, 33.6, -94.4, 37],
    "Oregon": [-124.7, 42, -116.5, 46.3],
    "Pennsylvania": [-80.5, 39.7, -74.7, 42.5],
    "Puerto Rico": [-68, 17.8, -65.2, 18.6],
    "Rhode Island": [-71.9, 41.1, -71.1, 42],
    "South Carolina": [-83.4, 32, -78.5, 35.2],
    "South Dakota": [-104.1, 42.5, -96.4, 45.9],
    "Tennessee": [-90.3, 35, -81.6, 36.7],
    "Texas": [-106.6, 25.8, -93.5, 36.5],
    "United States Virgin Islands": [-65.2, 17.6, -64.5, 18.5],
    "Utah": [-114.1, 37, -109, 42],
    "Vermont": [-73.4, 42.7, -71.5, 45],
    "Virginia": [-83.7, 36.5, -75.2, 39.5],
    "Washington": [-124.8, 45.5, -116.9, 49],
    "West Virginia": [-82.6, 37.2, -77.7, 40.6],
    "Wisconsin": [-92.9, 42.5, -86.2, 47.3],
    "Wyoming": [-111.1, 41, -104.1, 45]
} as { [key: string]: BBox };

export const abbreviations = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'MP': 'Commonwealth of the Northern Mariana Islands',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District of Columbia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'VI': 'United States Virgin Islands',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
} as { [key: string]: string };