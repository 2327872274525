import { NPMap } from "@npmap/base/dist/src/ui/map";
import { ParkInfo } from "../types";

const createElementWithStyle = <K extends keyof HTMLElementTagNameMap>(
    tag: K,
    style: Partial<CSSStyleDeclaration> = {}
): HTMLElementTagNameMap[K] => {
    const element = document.createElement(tag);
    Object.assign(element.style, style);
    return element;
}

export default () => (feature: { properties: ParkInfo }, popupTemplate?: any, map?: NPMap): HTMLElement => createPopup(feature.properties, map);

const createPopup = (parkInfo: ParkInfo, map?: NPMap): HTMLDivElement => {
    const containerDiv = createContainerDiv(parkInfo);
    const contentDiv = createContentDiv();
    const title = createTitle(parkInfo);
    const subText = createSubText(parkInfo);

    containerDiv.appendChild(contentDiv);
    contentDiv.appendChild(title);
    contentDiv.appendChild(subText);

    if (map) {
        map.fire('popupopen', { _target: containerDiv });
    }

    return containerDiv;
}

const createContainerDiv = (parkInfo: ParkInfo): HTMLDivElement => {
    const containerDiv = createElementWithStyle('div', {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        height: '150px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end'
    });

    containerDiv.tabIndex = -1;

    if (parkInfo.imageUrl) {
        containerDiv.style.backgroundImage = `url('${parkInfo.imageUrl}?mode=crop&quality=90&width=450&height=150')`;
    } else {
        containerDiv.style.backgroundColor = '#b2c294';
    }

    if (parkInfo.imageAltText) {
        containerDiv.setAttribute('aria-label', parkInfo.imageAltText);
    }

    return containerDiv;
}

const createContentDiv = (): HTMLElement => {
    return createElementWithStyle('div', {
        backgroundColor: 'rgba(0,0,0,0.6)',
        padding: '15px'
    });
}

const createTitle = (parkInfo: ParkInfo): HTMLAnchorElement => {
    const title = createElementWithStyle('a', {
        fontSize: '1.675rem',
        marginRight: '1.25rem',
        borderBottom: 'none',
        color: '#fff',
        fontFamily: "'Arial', sans-serif",
        fontWeight: 'bold',
        letterSpacing: '-1px',
        lineHeight: '24px',
        textDecoration: 'none',
    });

    title.textContent = parkInfo.parkName || parkInfo.parkLabel || parkInfo.parkFullName || '';
    title.setAttribute('target', '_blank');
    title.setAttribute('href', parkInfo.url || '#');

    return title;
}

const createSubText = (parkInfo: ParkInfo): HTMLDivElement => {
    const subTextDiv = createElementWithStyle('div', {
        fontSize: '.8125rem',
        color: '#ccc',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    });

    const states = parseStates(parkInfo.states);
    const designationInfo = parkInfo.designation || '';
    const statesString = states.join(', ').toUpperCase();
    const additionalInfo = [designationInfo, statesString].filter(Boolean).join(' - ');

    subTextDiv.textContent = additionalInfo || '';

    return subTextDiv;
}

const parseStates = (states: string[] | string): string[] => {
    if (typeof states === 'string') {
        try {
            return JSON.parse(states);
        } catch (e) {
            console.error('Error parsing states:', e);
            return [];
        }
    }
    return states;
}