import { Map as NPMap, NPMapOptions, NPMapStyleSpecification } from '@npmap/base';
import { StateInfo, ParkInfo, MapInfo, sortByDistance } from '../types';
import { getFullStyle, initialMapChanges, initialStyle } from './styles';

interface LoadMapDataOptions {
    stateList: StateInfo[];
    parkList: ParkInfo[];
    sortByDistance?: sortByDistance;
    displaySidebar: boolean;
    originalMapInfo: MapInfo;
    sidebarElement: HTMLElement;
}

/**
 * Loads the initial map data.
 * 
 * @param {LoadMapDataOptions} options - The options for loading the map data.
 * @returns {Promise<NPMap>} - A promise that resolves to the loaded NPMap instance.
 */
export default async function loadMapData(options: LoadMapDataOptions): Promise<NPMap> {
    const { stateList, parkList, displaySidebar, originalMapInfo: mapInfo, sidebarElement } = options;

    // Determine the map settings
    const newStyle = getFullStyle(stateList, parkList);
    const mapOptions = initialStyle(mapInfo, sidebarElement, displaySidebar, newStyle);

    // Define the initial map object and the options for creating or updating it
    let map = mapInfo.map;
    const toOptions: NPMapOptions = {
        ...mapOptions,
        controls: {} // Pass in empty controls, since they will be added later
    };

    if (!map) {
        // If no map exists, create a new one
        map = mapInfo.map = new NPMap({
            ...toOptions,
            style: {
                ...(toOptions.style as NPMapStyleSpecification),
                sources: {},
                layers: []
            }
        });

        // Update the map to have no rotation or tilt
        disableMapRotation(map);

        // Wait for the map to load or become idle
        await new Promise<void>((resolve) => {
            (map as NPMap).once('load', resolve);
            (map as NPMap).once('idle', resolve);
        });

        // Update the map with the new options and return it
        return await initializeMap(map, mapOptions);
    } else {
        // If a map already exists, update it
        disableMapRotation(map);

        if (map.loaded()) {
            // If the map is already loaded, update it directly
            return await initializeMap(map, mapOptions);
        } else {
            // Wait for the map to load or become idle before updating
            await new Promise<void>((resolve) => {
                (map as NPMap).once('load', resolve);
                (map as NPMap).once('idle', resolve);
            });

            // Update the map with the new options and return it
            return await initializeMap(map, mapOptions);
        }
    }
}

/**
 * Initializes the map with workarounds for known NPMap5 5.0.3 bugs.
 * 
 * This function applies map options in a specific sequence to avoid issues with
 * layers and controls initialization in NPMap5 5.0.3. It ensures proper loading
 * of the 'composite' source and waits for the map to be fully loaded before
 * completing the initialization process.
 *
 * @param {NPMap} map - The NPMap instance to initialize.
 * @param {NPMapOptions} mapOptions - The desired final options for the map.
 * @returns {Promise<NPMap>} A promise that resolves to the fully initialized NPMap instance.
 */
async function initializeMap(map: NPMap, mapOptions: NPMapOptions): Promise<NPMap> {
    // Step 1: Create options without layers to avoid NPMap5 5.0.3 bug
    const optionsWithoutLayers: NPMapOptions = {
        ...mapOptions,
        controls: {},
        style: {
            ...(mapOptions.style as NPMapStyleSpecification),
            sources: {},
            layers: []
        }
    };

    // Step 2: Apply options without layers and with empty controls
    map.updateOptions(optionsWithoutLayers, { ...optionsWithoutLayers, controls: {} });

    // Step 3: Ensure the 'composite' source is available
    if (!map.getSource('composite')) {
        await map._getSourceListener('composite');
    }

    // Step 4: Apply the actual intended options for the map
    map.updateOptions({ ...mapOptions, controls: {} }, optionsWithoutLayers);

    // Step 5: Wait for the map to finish loading
    await new Promise<void>((resolve) => {
        map.once('load', resolve);
        map.once('idle', resolve);
    });

    // Step 6: Complete the initialization process
    return completeLoad(map, mapOptions);
}

/**
 * Disables map rotation and tilt functionality.
 * 
 * @param {NPMap} map - The NPMap instance.
 */
function disableMapRotation(map: NPMap): void {
    // Disable rotation using right click + drag
    map.dragRotate.disable();

    // Disable rotation using keyboard
    map.keyboard.disableRotation();

    // Disable rotation using touch rotation gesture
    map.touchZoomRotate.disableRotation();
}


/**
 * Completes the map loading process.
 * 
 * @param {NPMap} map - The NPMap instance.
 * @param {NPMapOptions} toOptions - The options for loading the map.
 * @returns {Promise<NPMap>} - A promise that resolves to the loaded NPMap instance.
 */
async function completeLoad(map: NPMap, toOptions: NPMapOptions): Promise<NPMap> {
    initialMapChanges(map);

    // Workaround for a known bug in NPMap5 with controls
    Object.entries(toOptions.controls || {}).forEach(([controlName, controlOptions]) => {
        // Check if the control is already loaded
        if (controlOptions && (map._controls.filter(control => (control as any).npmapType === controlName)).length === 0) {
            map._processControl(controlName, controlOptions);
        }
    });

    return map;
}