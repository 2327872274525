import { ParkInfo, StateInfo } from "../types";
import { BoundingBox } from "@npmap/geotools";
import { BBox, Polygon } from "geojson";
import { Map as NPMap } from "@npmap/base";
import type { LngLatLike, PointLike } from "maplibre-gl";

export const getMaxZoomLevelForBbox = (map: NPMap, bbox: BBox | undefined, center: { lng: number, lat: number }, offsets: { left: number, right: number, padding: number }): {
    center: LngLatLike, zoom: number
} => {
    bbox = bbox || [center.lng, center.lat, center.lng, center.lat];
    const zooms = (new BoundingBox([{ x: bbox[0], y: bbox[1] }, { x: bbox[2], y: bbox[3] }], '4326'))
        .toGeoJSONGeometry().coordinates.flat().map(coord =>
            getMaxZoomLevelForPoint(map, { lng: coord[0], lat: coord[1] }, center, offsets)
        );

    const newCenter = calculateCenterWithOffset(map, center, offsets);

    return {
        center: newCenter,
        zoom: Math.min(...zooms)
    };
}

/**
 * Calculates the new center of the map considering the left and right offsets.
 *
 * @param {NPMap} map - The MapLibre map instance.
 * @param {LngLat} center - The original center of the map.
 * @param {Object} offsets - The offsets to consider.
 * @param {number} offsets.left - The left offset in pixels.
 * @param {number} offsets.right - The right offset in pixels.
 * @param {number} offsets.padding - The padding in pixels.
 * @returns {LngLat} - The new center of the map.
 */
const calculateCenterWithOffset = (map: NPMap, center: { lng: number, lat: number }, offsets: { left: number, right: number, padding: number }): LngLatLike => {
    // Project the original center to pixel coordinates
    const centerPixel = map.project(center);

    // Calculate the new center point in pixel coordinates
    const newCenterPixel: PointLike = [
        centerPixel.x + (offsets.left - offsets.right) / 2,
        centerPixel.y
    ];

    // Convert the new center point back to geographical coordinates
    const newCenter = map.unproject(newCenterPixel);
    return newCenter;
};

/**
 * Calculates the maximum zoom level where a point will still be displayed on the screen
 * with the map centered at a specified center point.
 * 
 * @param {Map} map - The MapLibre map instance.
 * @param {{lng: number, lat: number}} point - The point to be checked.
 * @param {{lng: number, lat: number}} center - The center point of the map.
 * @param {{left: number, right: number, padding: number}} offsets - The offsets for the map container.
 * @returns {number} - The maximum zoom level.
 */
const getMaxZoomLevelForPoint = (map: NPMap, point: { lng: number, lat: number }, center: { lng: number, lat: number }, offsets: { left: number, right: number, padding: number }): number => {
    const container = map.getContainer();
    const width = container.clientWidth - offsets.left - offsets.right - offsets.padding;
    const height = container.clientHeight - offsets.padding;

    // Calculate the distance from the center to the point in pixels at the current zoom level
    const centerPixel = map.project(center);
    const pointPixel = map.project(point);
    const distanceX = Math.abs(pointPixel.x - centerPixel.x);
    const distanceY = Math.abs(pointPixel.y - centerPixel.y);

    // Calculate the maximum distance in pixels the point can be from the center and still be on screen
    const maxDistanceX = width / 2;
    const maxDistanceY = height / 2;

    // Calculate the factor by which we need to zoom in
    const factorX = maxDistanceX / distanceX;
    const factorY = maxDistanceY / distanceY;
    const factor = Math.min(factorX, factorY);

    // Get the current zoom level and calculate the maximum zoom level
    const currentZoom = map.getZoom();
    const maxZoom = currentZoom + Math.log2(factor);

    return maxZoom;
}

/**
 * Calculates the combined bounding box of a list of states and a list of parks.
 *
 * This function first calculates the bounding box that encompasses all states in the given state list.
 * It then extends this bounding box to include all parks in the given park list.
 *
 * @param {StateInfo[]} stateList - The list of states with their bounding boxes.
 * @param {ParkInfo[]} parkList - The list of parks with their bounding boxes.
 * @returns {BoundingBox | undefined} - The combined bounding box of the states and parks, or undefined if no bounding boxes are provided.
 */
export const bboxStatesParks = (stateList: StateInfo[], parkList: ParkInfo[]): BoundingBox | undefined => {
    // Make shallow copies of the state and park lists to avoid mutating the original arrays.
    const stateListClean = [...stateList];
    const parkListClean = [...parkList];

    // Calculate the bounding box that encompasses all states in the list.
    const stateBBoxes = getBBox(stateListClean.map(state => state.bbox));
    let bbox: BoundingBox | undefined = stateBBoxes;

    // Calculate the bounding box that encompasses all parks in the list.
    const parkBBoxes = getBBox(parkListClean.map(park => park.bbox));

    // If both state and park bounding boxes exist, extend the state bounding box to include the parks.
    // If only park bounding boxes exist, set the bbox to the parks' bounding box.
    if (parkBBoxes) {
        bbox = bbox ? bbox.extend(parkBBoxes) : parkBBoxes;
    }

    return bbox;
};

/**
 * Combines multiple bounding boxes into a single bounding box.
 *
 * @param {(BoundingBox | BBox | Polygon | undefined)[]} bboxes - The array of bounding boxes to combine.
 * @returns {BoundingBox | undefined} - The combined bounding box, or undefined if no valid bounding boxes are provided.
 */
const getBBox = (bboxes: (BoundingBox | BBox | Polygon | undefined)[]): BoundingBox | undefined => {
    return bboxes.reduce<BoundingBox | undefined>((combinedBbox, bbox) => {
        if (!bbox) return combinedBbox;

        let bboxObj: BoundingBox | undefined;

        if (Array.isArray(bbox) && bbox.length === 4 && bbox.every(n => typeof n === 'number')) {
            bboxObj = new BoundingBox([
                { x: bbox[0], y: bbox[1] }, // SouthWest corner
                { x: bbox[2], y: bbox[3] }  // NorthEast corner
            ], '4326');
        } else if (isPolygon(bbox)) {
            bboxObj = new BoundingBox(bbox.coordinates[0].map(([x, y]) => ({ x, y })), '4326');
        } else if (bbox instanceof BoundingBox) {
            bboxObj = bbox;
        } else {
            console.warn('Unrecognized bbox format', bbox);
            return combinedBbox;
        }

        if (!bboxObj) return combinedBbox;

        try {
            const extendedBbox = nwBbox(bboxObj, 115);
            return combinedBbox ? combinedBbox.extend(extendedBbox) : extendedBbox;
        } catch (error) {
            console.error('Error extending bbox:', error);
            return combinedBbox;
        }
    }, undefined);
};

const isPolygon = (obj: any): obj is Polygon => {
    return obj &&
        obj.type === 'Polygon' &&
        Array.isArray(obj.coordinates) &&
        obj.coordinates.length > 0 &&
        Array.isArray(obj.coordinates[0]);
}

// Adjust the bounding box to ensure guam is west of hawaii
const nwBbox = (bbox: BoundingBox, lng: number): BoundingBox => {
    const [W, S, E, N] = bbox.toGeoJSONBBox('4326');
    // Loop guam around the earth
    if (E > lng || W > lng) {
        return new BoundingBox([{ x: W - 360, y: S }, { x: E - 360, y: N }], '4326');
    } else {
        return bbox;
    }
}