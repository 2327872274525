import { MapInfo, ParkInfo, StateInfo, sortByDistance } from "./types";
import { getAllParks, getParkList, getStateList } from "./data/services";
import { Map as NPMap, NPMapStyleSpecification } from '@npmap/base';
import type { BBox, Feature, FeatureCollection } from "geojson";
import type { GeoJSONSource, Marker } from "maplibre-gl";
import updateCards from "./ui/cards";
import { bboxStatesParks, getMaxZoomLevelForBbox } from "./data/bboxes";
import { addCenterMarker, getParksStyle, getStatesStyle } from "./ui/styles";
import { arrayToGeoJSON, createDiff, featuresToCollection } from "./data/Geojson";
import loadMapData from "./ui/initialLoad";
import Debouncer from "./ui/Debouncer";

export default class RelatedParks {
    private _originalMapInfo: MapInfo;
    private _mapPromise: Promise<NPMap>;
    private sidebarElement: HTMLDivElement;
    private currentLocationMarker: Marker | undefined;

    private parkList: ParkInfo[] = [];
    private stateList: StateInfo[] = [];
    private sortByDistance?: sortByDistance;
    private _currentFeatures: Feature[] = [];

    private popups: HTMLElement[] = [];

    private updateView = new Debouncer(this._updateView.bind(this), 300);

    static DefaultMapInfo: Required<MapInfo> = {
        center: { lng: -100, lat: 40 },
        container: undefined,
        displaySidebar: false,
        extent: undefined,
        mapOptions: undefined,
        zoom: 4,
        zoomOnUpdate: true,
        map: undefined,
        debug: false,
        padding: 120,
        maxScreenWidthPxSmall: 500,
        maxScreenWidthPxMedium: 1024,
        largeWidth: '275px',
        mediumWidth: '200px',
        smallWidth: '100%'
    };

    /**
     * Creates a RelatedParks instance and initializes the map and sidebar.
     * 
     * @param options - Partial map information to configure the map.
     * @param parkList - A list of parks to be displayed on the map.
     * @param stateAbbreviations - A list of state abbreviations to filter the states.
     * @param sortByDistance - An optional sorting configuration to prioritize parks based on distance.
     */
    constructor(
        options: Partial<MapInfo>,
        parkList: Partial<ParkInfo>[] = [],
        stateAbbreviations: string[] = [],
        sortByDistance?: sortByDistance
    ) {
        // Start fetching all park data asynchronously
        const requiredFields: (keyof ParkInfo)[] = ['unitCode', 'lat', 'lng'];
        const isComplete = (park: Partial<ParkInfo>) => requiredFields.every(field => park[field] !== undefined);
        // If all parks have the required fields, resolve the promise immediately, no need to go to the API to get more info
        const parkTablePromise = parkList.every(isComplete) ? Promise.resolve() : getAllParks();

        // Initialize the sidebar element
        this.sidebarElement = document.getElementById('relatedparks-sidebar') as HTMLDivElement || document.createElement('div');

        // Merge default map options with provided options
        this._originalMapInfo = { ...RelatedParks.DefaultMapInfo, ...options };

        // Set the state and park lists based on provided state abbreviations and sort by distance configuration
        this.stateList = getStateList(stateAbbreviations);
        this.parkList = []; // Start with an empty park list; it will be updated after data is fetched
        this.sortByDistance = sortByDistance;

        // Load the map with initial data
        this._mapPromise = this.loadMapWithData();

        // Enable debug mode if specified in the options
        if (this._originalMapInfo.debug === true) {
            (window as any).npmap = this._originalMapInfo.map;
        }

        // Once the park data and map are loaded, update the selected parks
        Promise.all([this._mapPromise, parkTablePromise]).then(([map]) => {

            this.updateSelectedParks(parkList);
            this.currentLocationMarker = addCenterMarker(map);
            this.setupPopupListener(map);
            this.setupSidebarToggle(map);
            this.adjustControlZIndex(map);
        });
    }

    private closeAllPopups() {
        while (this.popups.length) {
            const popup = this.popups.pop();
            const popupElement = popup?.parentElement?.parentElement;
            if (popupElement) {
                popupElement.remove();
            }
        }
    }

    /**
     * Loads the map with the initial data, including state and park lists.
     * 
     * @returns A promise that resolves when the map is fully loaded.
     */
    private loadMapWithData(): Promise<NPMap> {
        return loadMapData({
            stateList: this.stateList,
            parkList: this.parkList,
            sortByDistance: this.sortByDistance,
            displaySidebar: this._originalMapInfo.displaySidebar === true,
            originalMapInfo: this._originalMapInfo,
            sidebarElement: this.sidebarElement
        });
    }

    // Update the sorting (maxParks and center) for parks by distance
    updateSortByDistance(sortByDistance?: sortByDistance) {
        this.sortByDistance = sortByDistance;
        this.updateCenterAndZoom(this.stateList, this.parkList, sortByDistance);
    }

    // Update the selected states and their styles on the map
    updateSelectedStates(stateAbbreviations: string[] = []): StateInfo[] {
        this.stateList = getStateList(stateAbbreviations);
        const style = getStatesStyle(this.stateList);
        this.updateStyle(style);
        this.updateCenterAndZoom(this.stateList, this.parkList, this.sortByDistance);

        return this.stateList;
    }

    // Update the selected parks and refresh the map and sidebar
    async updateSelectedParks(partialParkList: Partial<ParkInfo>[] = []): Promise<ParkInfo[]> {
        this.parkList = getParkList(partialParkList);

        const sourceUpdate = this.updateGeoJSONSource('nps_parks', arrayToGeoJSON(this.parkList));
        await this.updateCenterAndZoom(this.stateList, this.parkList, this.sortByDistance);

        const style = getParksStyle(this.parkList);
        const updatePromise = this.updateStyle(style);
        const map = await this._mapPromise;

        // Update the sidebar with park cards
        updateCards(this.parkList, this.sidebarElement, map);

        await Promise.all([sourceUpdate, updatePromise, this._mapPromise]);
        return this.parkList;
    }

    private setupPopupListener(map: any) {
        map.on('popupopen', (popup: any) => {
            this.popups.push(popup._target);
            const sidebarParent = this.sidebarElement.parentElement?.parentElement;
            const sidebarWidth = sidebarParent?.parentElement?.clientWidth;
            if (sidebarWidth && sidebarWidth < this._originalMapInfo.maxScreenWidthPxSmall && sidebarParent?.classList.contains('sidebar-open')) {
                // close sidebar
                sidebarParent.classList.remove('sidebar-open');
            }
        });
    }

    private setupSidebarToggle(map: any) {
        const getSidebarWidth = (): number | undefined => this.sidebarElement.parentElement?.parentElement?.parentElement?.clientWidth;
        map.once('idle', () => {
            const toggleElement = map.getContainer().querySelector('.maplibregl-ctrl-sidebar-toggle');
            toggleElement?.addEventListener('click', () => {
                const sidebarWidth = getSidebarWidth();
                if (sidebarWidth && sidebarWidth < this._originalMapInfo.maxScreenWidthPxSmall) {
                    this.closeAllPopups();
                }
            });
        });
    }

    private adjustControlZIndex(map: any) {
        map.once('idle', () => {
            const controlDiv = map.getContainer().querySelector('.maplibregl-ctrl-top-left');
            controlDiv?.setAttribute('style', 'z-index: auto');
        });
    }


    // Update the style of the map layers based on new data
    private async updateStyle(styleLayers: NPMapStyleSpecification['layers']): Promise<void> {
        const map = await this._mapPromise;
        (styleLayers || []).forEach(layer => {
            const existingLayer = map.getLayer(layer.id);
            if (existingLayer) {
                const { id, filter, layout } = layer as typeof layer & { 'filter'?: any };
                if (filter && JSON.stringify(map.getFilter(id)) !== JSON.stringify(filter)) {
                    map.setFilter(id, filter);
                }
                if (layout && layout.visibility !== undefined && layout.visibility !== map.getLayoutProperty(id, 'visibility')) {
                    map.setLayoutProperty(id, 'visibility', layout.visibility);
                }
            }
        });
    }

    /**
     * Update the map's center and zoom level to fit new data
     * 
     * @param stateList - The states to fit the map view to.
     * @param parkList - The parks to fit the map view to.
     * @param sortByDistance - The sorting options for parks by distance.
     */
    private async updateCenterAndZoom(stateList: StateInfo[], parkList: ParkInfo[], sortByDistance: sortByDistance = {}): Promise<void> {
        const isLimited = sortByDistance.center !== undefined && sortByDistance.maxParks !== undefined;
        const limitedParks = isLimited ? parkList.slice(0, sortByDistance.maxParks) : parkList;
        const limitedStates = isLimited ? [] : stateList;
        const bbox = bboxStatesParks(limitedStates, limitedParks);
        const map = await this._mapPromise;

        // Add a marker for the current location if needed
        if (sortByDistance.center && this.currentLocationMarker) {
            this.currentLocationMarker
                .setLngLat([sortByDistance.center.lng, sortByDistance.center.lat])
                .addTo(map as any);
        } else {
            this.currentLocationMarker?.remove();
        }

        if (!bbox) return;

        this.updateView.call(bbox.toGeoJSONBBox('4326'), sortByDistance.center, { minZoom: sortByDistance.minZoom, maxZoom: sortByDistance.maxZoom });
    }

    /**
     * Calculates the padding for the map based on the provided padding value or the map's width.
     * 
     * If the provided padding is undefined or if the width of the map's container is less than
     * three times the provided padding, the function will return a third of the map's width as the padding.
     * Otherwise, it will return the provided padding value.
     * 
     * @param {number | undefined} padding - The padding value to use. If not provided, it defaults to `this._originalMapInfo.padding`.
     * @returns {Promise<number>} - A promise that resolves to the calculated padding value.
     */
    private async getCalculatedPadding(padding: number | undefined = this._originalMapInfo.padding): Promise<number> {
        const map = await this._mapPromise;
        const mapWidth = map.getContainer().clientWidth;
        const mapHeight = map.getContainer().clientHeight;
        if (padding === undefined || mapWidth < padding * 3 || mapHeight < padding * 3) {
            return Math.min(mapWidth, mapHeight) / 3;
        }
        return padding;
    }

    /**
     * Debounced function to update the map view based on bounding box, center, and zoom options.
     * 
     * @param bbox - The bounding box to fit the map view to.
     * @param center - The center coordinates to set the map view to.
     * @param zoom - An object containing optional minZoom and maxZoom values.
     */
    private async _updateView(
        bbox?: BBox,
        center?: { lng: number; lat: number },
        zoom: { minZoom?: number; maxZoom?: number } = {}
    ): Promise<void> {
        const map = await this._mapPromise;
        const padding = await this.getCalculatedPadding();

        // Helpers
        /////////////////////////////////////////////////
        // Calculate sidebar offsets
        const calculateSidebarOffsets = async (): Promise<{ leftOffset: number; rightOffset: number }> => {
            if (this._originalMapInfo.displaySidebar === true && !this.sidebarElement.parentElement?.parentElement) {
                await new Promise<void>(resolve => map.once('idle', () => resolve()));
            }

            const sidebarOpen = this.sidebarElement.parentElement?.parentElement?.classList.contains("sidebar-open");
            if (!sidebarOpen) return { leftOffset: 0, rightOffset: 0 };

            const sidebarElement = document.querySelector('.sidebar-open');
            return {
                leftOffset: sidebarElement?.querySelector('.left-sidebar-container')?.clientWidth ?? 0,
                rightOffset: sidebarElement?.querySelector('.right-sidebar-container')?.clientWidth ?? 0
            };
        };

        // Update view based on center
        const updateViewWithCenter = (leftOffset: number, rightOffset: number, center: { lng: number; lat: number }) => {
            const { zoom: newZoom, center: newCenter } = getMaxZoomLevelForBbox(map, bbox, center, {
                padding,
                left: leftOffset,
                right: rightOffset
            });

            if (newZoom !== undefined) {
                const boundedZoom = Math.min(Math.max(newZoom, zoom.minZoom ?? 0), zoom.maxZoom ?? 18);
                map.setZoom(boundedZoom);
            }

            if (newCenter) {
                map.setCenter(newCenter);
            }

            map.updateHomeControl({ homePosition: newCenter, zoom: newZoom });
        };

        // Update view based on bounding box
        const updateViewWithBBox = (leftOffset: number, rightOffset: number, bbox: BBox) => {
            const [west, south, east, north] = bbox;
            const extraPadding = (value: number) => (value > 0 ? value : 0);

            map.fitBounds([[west, south], [east, north]], {
                padding: {
                    top: padding,
                    bottom: padding,
                    right: padding + extraPadding(rightOffset),
                    left: padding + extraPadding(leftOffset)
                }
            });

            map.once('moveend', () => {
                map.updateHomeControl({ homePosition: map.getCenter(), zoom: map.getZoom() });
            });
        };
        ///////////////////////////////////////////////// 

        // Get the sidebar offsets
        const { leftOffset, rightOffset } = await calculateSidebarOffsets();

        if (center) {
            // If there's a center passed in, center the map on it
            updateViewWithCenter(leftOffset, rightOffset, center);
        } else if (bbox) {
            // Otherwise, fit the map view to the bounding boxs
            updateViewWithBBox(leftOffset, rightOffset, bbox);
        }
    }

    /**
     * Updates the GeoJSON source by comparing current data with new park data.
     * It identifies new parks to be added and old parks to be removed.
     * 
     * @param {string} sourceName - The name of the GeoJSON source to update.
     * @param {FeatureCollection} data - The new park data to update the source with.
     * @returns {Promise<GeoJSONSource>} - A promise that resolves to the updated GeoJSON source.
     */
    private async updateGeoJSONSource(sourceName: string, data: FeatureCollection): Promise<GeoJSONSource> {
        const map = await this._mapPromise;
        let source = map.getSource(sourceName) as GeoJSONSource | undefined;

        if (!source) {
            map.addSource(sourceName, { type: 'geojson', data });
            await map._getSourceListener(sourceName);
            source = map.getSource(sourceName) as GeoJSONSource;
        } else {
            const currentFeatureCollection = featuresToCollection(this._currentFeatures);
            const { add, remove } = createDiff(currentFeatureCollection, data);

            if (add) this._currentFeatures.push(...add);
            if (remove) {
                this._currentFeatures = this._currentFeatures.filter(f => f.id !== undefined && !remove.includes(f.id));
            }

            source.setData({
                type: 'FeatureCollection',
                features: this._currentFeatures
            });
        }

        if (!source) {
            throw new Error(`Failed to create or retrieve source: ${sourceName}`);
        }

        return source;
    }
}